/* timeline.css */
.timeline-container {
    position: relative;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.timeline-horizontal {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin-bottom: 20px;
}

.timeline-horizontal::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #e5e7eb;
    transform: translateY(-50%);
}

.timeline-event {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 20%;
}

.timeline-event-marker {
    background-color: #1d2975;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    margin-bottom: 8px;
    position: relative;
    z-index: 1;
}

.timeline-event-content {
    background-color: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100px; /* Ensures all boxes have the same width */
}
